@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Cabin", sans-serif;
}

p,
small,
input,
select,
div,
button,
a {
  font-family: "Cabin", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer components {
  .page_title {
    @apply font-semibold text-yankee_blue text-lg s:text-xl capitalize;
  }
  .page_subtitle {
    @apply font-semibold text-yankee_blue text-sm s:text-lg;
  }

  .page_title_container {
    @apply flex items-center gap-2;
  }

  .side_page_title {
    @apply font-semibold text-yankee_blue text-base mb-5;
  }

  .sidebar_inactive {
    @apply text-auro_metal_saurus text-sm z-50;
  }

  .sidebar_active {
    @apply text-primary text-sm z-50;
  }

  .main_content {
    @apply bg-white shadow-sm py-5;
  }

  .main_content_header {
    @apply bg-white shadow-sm mb-5 py-2;
  }

  .main_content_header_btn {
    @apply ml-1 text-sm py-1 px-2 font-thin text-morning_blue hover:text-independence hover:border-b-4;
  }

  .main_content_header_btn_active {
    @apply text-independence font-bold border-b-4 border-primary;
  }

  .page {
    @apply py-[2%] px-3 s:px-5 xl:mr-[300px];
  }

  .full_page {
    @apply py-[2%] px-3 s:px-5 relative;
  }

  .page_dark_overlay {
    @apply fixed top-0 left-0 bottom-0 xl:hidden right-[300px] bg-black/50;
  }

  .page_right_bar {
    @apply bg-white fixed top-0 bottom-0 right-0 w-full min-[501px]:max-w-[300px] min-w-[300px] hidden xl:block overflow-auto py-[2%] px-3;
  }

  .auth_page {
    @apply md:grid md:grid-cols-2 min-h-screen;
  }

  .auth_logo_wrapper {
    @apply flex justify-center;
  }

  .auth_page_content_grid {
    @apply px-[10%] py-[4%];
  }

  .auth_page_content {
    @apply max-w-sm mx-auto;
  }

  .auth_page_title {
    @apply text-yankee_blue text-xl text-center font-semibold mt-[10%] mb-5;
  }

  .auth_page_subtitle {
    @apply mb-10 text-yankee_blue text-base;
  }

  .auth_page_image_grid {
    @apply bg-primary_light_20 hidden md:flex items-center;
  }

  .auth_image_container {
    @apply max-w-[100%] h-[70vh] mx-auto;
  }

  .profile {
    @apply py-3;
  }

  .profile_title {
    @apply font-bold text-yankee_blue mb-3 text-lg capitalize flex items-center gap-4;
  }

  .profile_subtitle {
    @apply font-bold text-yankee_blue mb-2 text-base capitalize;
  }

  .profile_data {
    @apply grid sm:grid-cols-2;
  }

  .table_wrapper {
    @apply overflow-auto max-w-[90vw];
  }

  .table_wrapper {
    @apply overflow-auto max-w-[100vw];
  }

  .table_header {
    @apply min-w-[700px] grid grid-cols-4 gap-1 border-b pb-5;
  }

  .table_cell_round_btn {
    @apply border;
  }

  .table_row {
    @apply cursor-pointer;
  }

  .active_table_row {
    @apply bg-primary_light_30;
  }

  .table_icon {
    @apply text-lg p-2 text-primary rounded-full hover:border-none hover:bg-primary hover:text-white;
  }
}
